import axios from 'axios';

import { getApiUrl, getGuid, getSs } from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { userState } from '../../stores/userStore';
import { gmmAlert } from '../../utils';
import { alerts } from '../alerts';
import { isAxiosError, toBody } from '../utils';

import {
  handleSendObjectResponse,
  handleSendObjectErrorResponse,
} from './responseHandler';
import { SendObjectType } from './types';

interface SendObjectOptions<Data> {
  onSuccess?: (data: Data) => void;
  skipToggleLoading?: boolean;
}

export async function sendObject<Data>(
  {
    a = new Date().getTime(),
    ss = getSs(),
    user = userState().userName,
    guid = getGuid(),
    ...obj
  }: SendObjectType,
  { onSuccess, skipToggleLoading }: SendObjectOptions<Data> = {}
): Promise<void> {
  try {
    if (!obj?.type) {
      console.error('No object to send!');

      return;
    }

    const payload = {
      ...obj,
      a,
      ss,
      user,
      guid,
    };

    const serialized = toBody(payload);

    if (!skipToggleLoading) studentAppModalState().setLoading(true);

    const url = `${getApiUrl()}/SendObject`;

    const { data } = await axios.post(url, serialized);

    await handleSendObjectResponse(data);

    onSuccess?.(data);

    return;
  } catch (err) {
    if (isAxiosError(err)) {
      handleSendObjectErrorResponse(obj, err);
    }

    gmmAlert(alerts.connectionTrouble);
    console.error(err);
  } finally {
    studentAppModalState().setLoading(false);
  }

  return;
}
