import { styled } from '@material-ui/core';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { FC, useEffect } from 'react';

import { flippingBox } from '../../../animations';
import { useSequenceAnimation } from '../../../hooks';
import { NormalProblem, problemStore } from '../../../stores';
import { getColorData } from '../../../utils';

import { BoxFace } from './boxFace';

const AnimationContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  perspective: 1000,
  flexShrink: 0,
  minHeight: 200,
  overflow: 'hidden',
}));

const MotionDiv = styled(motion.div)(() => ({
  width: 125,
  height: 125,
  position: 'relative',
  transformOrigin: '50% 50%',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
}));

interface Props {
  problem: NormalProblem;
  initial?: MotionProps['initial'];
  start?: boolean;
}

export const ModalBoxAnimation: FC<Props> = ({
  problem,
  initial = 'initial',
  start = true,
}) => {
  const isWrongOrPenalty = !!problem.penalty || problem.status === 'wrong';
  const isDollar = problemStore(state => state.dollars.includes(problem.id));
  const latestLevel = getColorData(problem.lvl).level;
  const previousLevel = latestLevel - 1;
  const levels = [previousLevel, latestLevel];
  const { boxFaceClassName, variants } = flippingBox.modal;
  const { controls, startAnimation, stopAnimation } = useSequenceAnimation({
    sequence: [
      'slideIn',
      'grow',
      'flip',
      'shimmer',
      'pause',
      'scaleDown',
      'slideOut',
    ],
    onComplete: () => {},
    loop: start,
  });

  useEffect(() => {
    if (start) startAnimation();
    if (!start) stopAnimation();
  }, [start, startAnimation, stopAnimation]);

  return (
    <AnimationContainer>
      <AnimatePresence>
        <MotionDiv animate={controls} initial={initial} variants={variants}>
          {levels.map(level => (
            <BoxFace
              className={boxFaceClassName}
              iconSize={65}
              isBack={level === latestLevel}
              isDollar={isDollar}
              key={level}
              level={level}
              isPenaltyOrWrong={isWrongOrPenalty}
            />
          ))}
        </MotionDiv>
      </AnimatePresence>
    </AnimationContainer>
  );
};

ModalBoxAnimation.displayName = 'ModalBoxAnimation';
