import { AlertOptions } from '@gmm/problem';

export const alerts = {
  assignmentChanged: {
    msg: `Your teacher changed what your class is working on right now.  We've changed the problems on your screen.`,
    top: 'Problems Changed',
  },
  // Currently only used in when logging in ('onboardingApp')
  badCredentials: {
    msg: "Can't find that user/password combination.",
    top: 'Credentials Failed',
  },
  choiceFail: {
    msg: 'GMM is unable to make the change you requested.',
    top: 'Change Not Possible',
  },
  clasOff: {
    msg:
      'Your teacher has not given your class any problems to work on yet. Please ask your teacher to give your class an Assignment.',
    top: 'No Work To Do',
  },
  connectionFailure: {
    msg: 'GMM hit a connection failure.  Please restart.',
    reload: true,
    top: 'Connection Failed',
  },
  connectionTrouble: {
    msg: 'Hmmm, GMM is having communication problems. Please restart.',
    reload: true,
    top: 'Connection Trouble',
  },
  device: {
    msg:
      'Your device has an out-dated GMM page. Refresh your screen and log in again. If you still get this message, you may need to clear your browser cache.',
    top: 'Please Refresh',
  },
  emptyExam: {
    msg:
      'The exam you requested is empty. If it was a follow-up, perhaps you got 100% on the original exam!',
    top: 'Message',
  },
  followUpChanged: {
    msg:
      'Your teacher has updated your Follow-Up Exam.' +
      '  You will now be placed back into normal math practice.' +
      '  You may request to continue working on the Exam when you are ready.',
    reload: true,
    top: 'Exam Updated',
    reloadIsInternal: true,
  },
  lockedOut: (remainingMinutes: number): AlertOptions => {
    const firstPart =
      'Oops, your account has been locked due to too many failed login attempts. Please try again in';
    const units = remainingMinutes < 2 ? 'minute' : 'minutes';
    const lastPart = 'or ask your teacher to reset your password.';

    return {
      msg: `${firstPart} ${remainingMinutes} ${units} ${lastPart}`,
      top: 'Account Locked',
    };
  },
  loggedOff: (reason: string): AlertOptions => {
    return {
      msg: reason,
      reload: true,
      top: "You've Been Logged Off",
    };
  },
  loginFailed: {
    msg:
      'Uh-oh, there was an error during login. Is your internet connection live?',
    reload: true,
    top: 'Login Failed',
  },
  notFinished: {
    msg: 'You must finish the selected problem first.',
    top: 'Not Possible',
  },
  noClas: {
    msg: 'You are not a member of any active classes.',
    top: 'No Class',
  },
  oldVersion: {
    msg:
      'Your device has an out-dated GMM page. Refresh your screen and log in again. If you still get this message, you may need to clear your browser cache.',
    top: 'Please Refresh',
  },
  pausedExam: {
    msg:
      'Your teacher has paused your progress on this Exam. You will now be placed back into normal math practice. You may request to continue working on the Exam when you are ready.',
    reload: true,
    top: 'Teacher Paused Exam',
    reloadIsInternal: true,
  },
  examRemoved: {
    msg:
      'Your teacher has removed this Exam for your class.' +
      '  You will now be placed back into normal math practice.',
    reload: true,
    top: 'Exam Removed',
    reloadIsInternal: true,
  },
  requestDenied: {
    msg: "Your teacher denied your 'unlock' request.",
    top: 'Request Denied',
  },
  sessionExpired: {
    msg: 'Session Expired',
  },
  settingsChanged: {
    msg: 'Your teacher changed your multiple choice settings.',
    reload: true,
    top: 'Settings Changed',
    reloadIsInternal: true,
  },
  severeError: (msg: string): AlertOptions => {
    return {
      msg,
      style: 'red',
      top: 'Severe Error',
    };
  },
  // 'sticky' is what server sends if it can't figure out skill code
  snafu: (probFailType: string): AlertOptions => {
    return {
      msg: `GMM cannot create any more of this type of problem! Please tell your teacher to inform GMM that ${probFailType} is broken.`,
      reload: true,
      top: 'Technical Difficulty',
    };
  },
  submitFail: (msg: string): AlertOptions => {
    return {
      msg,
      top: 'Submit Failed',
    };
  },
  teacher: {
    msg: 'Teacher web login not supported here.',
    top: 'Wrong Site',
  },
  teacherCredits: {
    msg: 'Your teacher increased your game credits.',
    top: 'Good News',
  },
  turnedInExam: {
    msg:
      'Your teacher has turned in your exam.  If you want to continue working on it, ' +
      'you must request the exam again.',
    reload: true,
    top: 'Teacher Turned In Exam',
    reloadIsInternal: true,
  },
};
