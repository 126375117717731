import { AxiosError } from 'axios';

import { ProblemData } from '@gmm/problem';

import {
  ReceiveProblem,
  processAvailableWork,
  receiveProblem,
  updateGameCredits,
} from '../../api/responseHandlerShared';
import { bannerState, problemState } from '../../stores';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { newWork } from '../../stores/workStore';
import { Assignment, NewWork } from '../../types';
import { gmmAlert } from '../../utils';
import { alerts } from '../alerts';
import { sendErrorToServer } from '../sendError';
import { getErrorStatus, handleFail, handleSessionClosed } from '../utils';

import { SendObjectType } from './types';

interface ReplacementFailed {
  dialogMessage: string;
  replacementsPerDay: number;
}

export interface SendObjectResponse {
  availableWork?: Assignment[];
  choiceFail?: boolean;
  fail?: string;
  gameCredits?: number;
  guidFail?: string;
  id?: number;
  override?: {
    p: ProblemData;
  };
  sessionClosed?: boolean;
  replacementFail?: ReplacementFailed;
  st?: NewWork;
}

const fail = (data: SendObjectResponse): void => {
  if (!data.fail) return;
  const msg = data.fail;

  if (data.gameCredits) updateGameCredits(data.gameCredits);

  studentAppModalState().setLoading(false);

  gmmAlert({ msg });

  return;
};

// Possibly vestigial, as clicking 'override' button on Mobius triggers
// a 'targetedOverride', not this plain old 'override'
const override = async (data: SendObjectResponse): Promise<void> => {
  if (!data.id || !data.override) return;

  const id = `${data.id}`;

  const problem: ReceiveProblem = {
    problem: data.override.p,
    id,
    performingTargetedOverride: true,
  };

  // If there is a pending replacement problem, the student is currently
  // viewing the answer to a problem on the 'Show Answer' dialog.
  // The override should be delayed until the student closes dialog.
  // Also, the inbound override should become the replacement problem,
  // since the inbound problem is now the official state for the Restore
  // on the server.
  const existingReplacementProblem = studentAppModalState().replacementProblem;

  // == because one id is a string and the other is a number
  if (existingReplacementProblem && existingReplacementProblem.id == id) {
    studentAppModalState().setReplacementProblem(problem);

    return;
  }

  problemState().resetSquare(id);
  receiveProblem(problem);
};

export const handleSendObjectResponse = (data: SendObjectResponse): void => {
  if (data.guidFail) return handleFail(data.guidFail);

  if (data.sessionClosed) return handleSessionClosed();

  if (data.replacementFail) {
    return handleReplacementFailNoReplacements(data.replacementFail);
  }

  if (data.fail) return fail(data);

  if (data.choiceFail) return gmmAlert(alerts.choiceFail);

  newWork(data.st);

  if (data.override) {
    override(data);

    return;
  }

  processAvailableWork(data.availableWork);
};

const handleReplacementFailNoReplacements = (
  response: ReplacementFailed
): void => {
  bannerState().setReplacementsPerDay(response.replacementsPerDay);

  gmmAlert({
    msg: response.dialogMessage,
    top: 'Cannot Replace',
  });
};

export const handleSendObjectErrorResponse = (
  received: SendObjectType,
  err: AxiosError
): void => {
  const error = getErrorStatus(err);
  const type = `SendObject < ${received.type}> failed`;
  const statusError = `${error.status}\n${error.error}`;
  const errorMessage =
    received.type === 'gameStopped'
      ? `${type} for ${received.game} with save string length: ${received.data?.length} \n ${statusError}`
      : `${type}\n${statusError}`;

  studentAppModalState().setLoading(false);

  sendErrorToServer(errorMessage);
};
