import axios from 'axios';
import { isBoolean } from 'lodash';

import { bannerState } from '../../stores';
import {
  getApiUrl,
  getIsTest,
  getLastMsgId,
  setLastMsgId,
} from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { userState } from '../../stores/userStore';
import { newWork, workState } from '../../stores/workStore';
import { LoginRequest } from '../login';
import {
  notifyNewExam,
  processAvailableWork,
  updateExamsList,
  updatePermitReadAloud,
  updateSwitchToSpiralReviewSettings,
} from '../responseHandlerShared';
import {
  handleFail,
  handleSessionClosed,
  isAxiosError,
  processAxiosFailure,
  toBody,
} from '../utils';

import {
  snackbarNotifications,
  clasMessages,
  unlockExamProblems,
  updateGameCreditsAndTopScores,
  updateWallet,
  updateAllowReplacementsWhenTeacherOnline,
  updateReplacements,
} from './responseHandler';
import { teacherToStudentMessages } from './teacherToStudentMessages';
import { PingSuccess } from './types';

interface MessageCheckParams extends LoginRequest {
  getTopScores?: boolean;
  tg?: number;
}

interface PingOptions {
  onSuccess?: () => void;
  onPayloadProcessed?: () => void;
  onAxiosError?: () => void;
}

export async function messageCheck(
  getTopScores = false,
  { onSuccess, onAxiosError, onPayloadProcessed }: PingOptions
): Promise<void> {
  try {
    const params: MessageCheckParams = {
      ss: userState().redisSessionId,
      lastMsgId: getLastMsgId(),
      guid: userState().guid,
      user: userState().userName,
    };

    if (getTopScores) params.getTopScores = true;

    if (getIsTest()) params.tg = workState().currentWork.sitId;

    const url = `${getApiUrl()}/MessageCheck`;

    const { data } = await axios.post(url, toBody(params));

    const pingSuccess = data as PingSuccess;

    onSuccess?.();

    if (pingSuccess.sessionClosed) return handleSessionClosed();

    if (pingSuccess.guidFail) return handleFail(pingSuccess.guidFail);

    setLastMsgId(pingSuccess.lastMsgId);

    if (!getIsTest()) {
      updateGameCreditsAndTopScores(pingSuccess);
      newWork(pingSuccess.st);
    }

    if (isBoolean(pingSuccess.aH)) {
      // don't let a student's raised hand persist
      if (!pingSuccess.aH) bannerState().setIsHandRaised(false);
      bannerState().setAllowHand(pingSuccess.aH);
    }

    if (isBoolean(pingSuccess.bgclas)) {
      bannerState().setBlockGamesClass(data.bgclas);
    }

    if (pingSuccess.sendMoney) updateWallet(pingSuccess.sendMoney);

    bannerState().setTeacherOnline(pingSuccess.tO);

    if (pingSuccess.hw || pingSuccess.hw === 0) {
      bannerState().setDailyGoal(pingSuccess.hw);
    }

    if (pingSuccess.games)
      studentAppModalState().setAvailableGames(pingSuccess.games);

    if (pingSuccess.clasMsgs) clasMessages(pingSuccess);

    if (pingSuccess.addTests) {
      updateExamsList({
        testData: pingSuccess.addTests,
        isAdd: true,
      });
    }

    if (isBoolean(pingSuccess.autoUnlockExamsProblems) && getIsTest())
      unlockExamProblems();

    if (pingSuccess.removeTests) {
      updateExamsList({
        testData: pingSuccess.removeTests,
        isAdd: false,
      });
    }

    if (pingSuccess.switchToSpiralReviewSettings) {
      updateSwitchToSpiralReviewSettings(
        pingSuccess.switchToSpiralReviewSettings
      );
    }

    if (isBoolean(pingSuccess.allowReplacementsWhenTeacherOnline)) {
      updateAllowReplacementsWhenTeacherOnline(
        pingSuccess.allowReplacementsWhenTeacherOnline
      );
    }

    processAvailableWork(pingSuccess.availableWork);

    if (pingSuccess.notifications) snackbarNotifications(pingSuccess);

    if (pingSuccess.notifyNewExam)
      notifyNewExam(pingSuccess.notifyNewExam.sitId);

    if (pingSuccess.m) teacherToStudentMessages(pingSuccess);

    updateReplacements(pingSuccess.replacementsPerDay);
    updatePermitReadAloud(pingSuccess.permitReadAloud);

    return onPayloadProcessed?.();
  } catch (err) {
    if (!isAxiosError(err)) return;

    const result = processAxiosFailure(err);

    if (result) onAxiosError?.();
  }

  return;
}
