import { bannerState, problemState } from '../../stores';
import {
  getIsTest,
  getPingIDDebug,
  setPingIDDebug,
  setSnackbarMessage,
} from '../../stores/globalState';
import { GAME, studentAppModalState } from '../../stores/studentAppModalStore';
import { workState } from '../../stores/workStore';
import { Exam, ID } from '../../types';
import { gmmAlert } from '../../utils';
import { logHistory } from '../../utils/gmmUtils';
import { alerts } from '../alerts';
import {
  unlockAll,
  updateExamsList,
  updateGameCredits,
  updateSwitchToSpiralReviewSettings,
} from '../responseHandlerShared';
import { postAssignmentChoice } from '../sendObject';

import { PingSuccess } from './types';

interface DebugDelta {
  pingIDDebugInner: void;
  timeDelta: number;
}

const getDeltas = (startDate: Date): DebugDelta => {
  const stopDate = new Date();

  return {
    pingIDDebugInner: setPingIDDebug(getPingIDDebug() + 1),
    timeDelta: stopDate.getTime() - startDate.getTime(),
  };
};

export const storeSuccessTime = (startDate: Date): void => {
  const { pingIDDebugInner, timeDelta } = getDeltas(startDate);

  logHistory(`success on ping #${pingIDDebugInner} in ${timeDelta}`);
};

export const storeFailTime = (startDate: Date): void => {
  const { pingIDDebugInner, timeDelta } = getDeltas(startDate);

  logHistory(`failure on ping ${pingIDDebugInner} after ${timeDelta}`);
};

export const updateGameCreditsAndTopScores = (data: PingSuccess): void => {
  if (data.gameCredits) {
    updateGameCredits(
      data.gameCredits,
      data.pointsTowardGameCredit,
      data.pointsForGameCredit
    );
  }

  if (data.topScores) {
    studentAppModalState().setTopScores(data.topScores.scoreRows);
  }

  if (data.clasHighestToday) {
    bannerState().setClassHighestToday(data.clasHighestToday);
  }

  return;
};

export const updateWallet = (amount: number): void => {
  updateGameCredits(amount);
  gmmAlert(alerts.teacherCredits);
};

export const updateReplacements = (
  replacementsPerDay: number | undefined
): void => {
  if (replacementsPerDay !== undefined) {
    bannerState().setReplacementsPerDay(replacementsPerDay);
  }
};

export const updateAllowReplacementsWhenTeacherOnline = (
  allowReplacementsWhenTeacherOnline: boolean
): void => {
  bannerState().setAllowReplacementsWhenTeacherOnline(
    allowReplacementsWhenTeacherOnline
  );
};

export const clasMessages = (data: PingSuccess): void => {
  data.clasMsgs.forEach(msg => {
    studentAppModalState().addStackableMessageFromTeacher(msg.msg);
  });
};

export const unlockExamProblems = (): void => {
  problemState().setAutoUnlockExamsProblems(true);
  unlockAll();
};

export const snackbarNotifications = (data: PingSuccess): void => {
  data.notifications?.forEach(notification => {
    switch (notification.type) {
      case 'allowExamsOnMobile':
        bannerState().setAllowExamsOnMobile(
          notification.json.allowExamsOnMobile
        );
        break;

      case 'allowWorkOnAnyProblems':
        setSnackbarMessage({
          autoHideDuration: null,
          message:
            'Your teacher updated a class setting that changed the problems you can work on.',
        });
        problemState().setDollarsOnly(
          !notification.json.allowWorkOnAnyProblems
        );
        break;

      case 'examRemovedFromClass':
        // only alert if the student is in the exam
        examRemovedFromClass(notification.json.testId);

        updateExamsList({
          testData: [notification.json],
          isAdd: false,
        });

        break;

      case 'pauseExam':
        // only alert if the student is in the exam
        pauseExam(notification.json.testId);
        break;

      case 'workChangedAlert':
        if (studentAppModalState().currentModal !== GAME && !getIsTest()) {
          gmmAlert(alerts.assignmentChanged);
        }

        break;

      case 'requireWorkOnDefaultAssignment':
        {
          const availableWork = studentAppModalState().availableWork;
          const currentWork = workState().currentWork;
          const defaultAssignment = availableWork.find(
            work => work.defaultAssignment
          );
          const isDefaultAssignmentCompleted =
            defaultAssignment &&
            defaultAssignment.points! >= defaultAssignment.required!;

          updateSwitchToSpiralReviewSettings({
            requireStudentsToFinishDefaultAssignment:
              notification.json.requireWorkOnDefaultAssignment,
            isClasDefaultWorkSpiralReview: workState()
              .isClasDefaultWorkSpiralReview,
          });

          if (!isDefaultAssignmentCompleted) {
            postAssignmentChoice(
              defaultAssignment!.workId!,
              defaultAssignment!.workType!
            );

            if (
              workState().requireStudentsToFinishDefaultAssignment &&
              currentWork.workId !== defaultAssignment?.workId &&
              studentAppModalState().currentModal !== GAME &&
              !getIsTest()
            )
              gmmAlert(alerts.assignmentChanged);
          }
        }

        break;
    }
  });
};

const examRemovedFromClass = function (testIdToRemove: ID): void {
  const work = workState().currentWork;

  if (work.type !== 'EXAM') return;

  const exam = work as Exam;
  const testId = exam.testId;

  // only alert if the student is in an exam
  if (testId === testIdToRemove) {
    gmmAlert(alerts.examRemoved);
  }
};

const pauseExam = function (testIdToRemove: ID): void {
  const work = workState().currentWork;

  if (work.type !== 'EXAM') return;

  const exam = work as Exam;

  if (!exam) return;
  const testId = exam.testId;

  // only alert if the student is in an exam
  if (testId === testIdToRemove) {
    gmmAlert(alerts.pausedExam);
  }
};
