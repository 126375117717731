import axios from 'axios';

import { ProblemData } from '@gmm/problem';

import {
  getApiUrl,
  getGuid,
  getSicId,
  getSs,
  getUsername,
} from '../../stores/globalState';
import {
  handleFail,
  handleSessionClosed,
  isAxiosError,
  ParsedAxiosError,
  processAxiosFailure,
  toBody,
} from '../utils';

interface RestoreOptions {
  onSuccess?: (data: ProblemData) => void;
  onAxiosError?: (axiosError: ParsedAxiosError) => void;
}

export async function sendRestoreRequest(
  id: string,
  isTest = false,
  { onSuccess, onAxiosError }: RestoreOptions = {}
): Promise<void> {
  try {
    const url = `${getApiUrl()}/ServletRestore`;

    const body = {
      a: new Date().getTime(),
      guid: getGuid(),
      id,
      isTest,
      sicId: getSicId(),
      ss: getSs(),
      user: getUsername(),
    };

    const { data } = await axios.post(url, toBody(body));

    if (data.sessionClosed) return handleSessionClosed();

    const isFail = data.guidFail || data.fail;

    if (isFail) handleFail(isFail);

    return onSuccess?.(data.p);
  } catch (err) {
    if (!isAxiosError(err)) return;

    const result = processAxiosFailure(err);

    if (result) onAxiosError?.(result);
  }

  return;
}
