import { sendErrorToServer } from '../../studentApp/api';
import {
  messageCheck,
  storeFailTime,
  storeSuccessTime,
} from '../../studentApp/api/messageCheck';
import { bannerState } from '../../studentApp/stores';
import {
  getPingPoliceId,
  setInactiveTimer,
  setLastPing,
  setPingPoliceId,
  getPingId,
  setPingFail,
  getPingFail,
  setPingId,
  getLastPingPolice,
  getLastPing,
  setPingLevel,
  getPingLevel,
  setLastPingPolice,
} from '../../studentApp/stores/globalState';
import {
  isReloading,
  studentAppModalState,
} from '../../studentApp/stores/studentAppModalStore';
import { reboot, turnOffAllTimers } from '../utils/gmmUtils';

import { inactiveCheck } from './activityMonitor';

const MESSAGE_CHECK_INTERVAL = 5000;

export const initializePingPolice = (): void => {
  nextPing('login');
  setInactiveTimer(setInterval(inactiveCheck, MESSAGE_CHECK_INTERVAL));

  // err, shouldn't need this, but groping for reason that
  // pingPolice should fire 'activated' three times within a few ms
  if (getPingPoliceId()) clearInterval(getPingPoliceId()!);
  setPingPoliceId(setInterval(pingPolice, 5000));
  setLastPing(new Date().getTime());
};

function ping(force: boolean): void {
  if (isReloading()) {
    turnOffAllTimers();

    return;
  }

  if (!force && studentAppModalState().loading) {
    console.log('Delay ping: loading');
    nextPing('loading');

    return;
  }

  setLastPing(new Date().getTime());
  const myPingLevel = getPingLevel();
  const startDate = new Date();

  const getTopScores = bannerState().showTopScores;

  messageCheck(getTopScores, {
    onSuccess: () => storeSuccessTime(startDate),
    onPayloadProcessed: () => handlePingLevel(myPingLevel, 'success'),
    onAxiosError: () => {
      storeFailTime(startDate);

      sendErrorToServer('Ping fail ' + getPingFail());

      handlePingLevel(myPingLevel, 'fail');
    },
  });
}

export function nextPing(caller: string): void {
  if (caller === 'ping fail') {
    setPingFail(getPingFail() + 1);

    if (getPingFail() > 4) {
      sendErrorToServer('quadruple ping fail');

      const msg =
        "Uh-oh, looks like we can't connect to the server, please try to login again.";

      reboot({ msg });

      return;
    }
  } else {
    setPingFail(0);
  }

  setPingId(setTimeout(ping, 5000));
}

// ugly patch.  Sometimes client stops polling (nextPing).  Haven't figured out
// why, so this...
export function pingPolice(): void {
  if (isReloading()) return;
  const time = new Date().getTime();
  const lastPing = getLastPing();

  if (time > lastPing + 60000) {
    const lastPingPolice = getLastPingPolice();

    if (!lastPingPolice || time > lastPingPolice + 30000) {
      if (inactiveCheck()) return;
      clearTimeout(getPingId()!);
      setPingLevel(getPingLevel() + 1);
      const msg =
        'Ping police activated cuz lastPing was ' +
        (time - lastPing) +
        ' ms ago, moved pingLevel to ' +
        getPingLevel();

      ping(true);
      setLastPingPolice(new Date().getTime());
      sendErrorToServer(msg);
    }
  }
}

const handlePingLevel = (pingLevel: number, type: 'success' | 'fail'): void => {
  if (pingLevel === getPingLevel()) {
    nextPing(`ping ${type}`);
  } else {
    const msg = `dropped nextPing call <${type}>
    cuz my ping level is outdated: ${pingLevel} vs. ${getPingLevel()}`;

    sendErrorToServer(msg);
  }
};
