import { sendObject } from './handler';

export type RestoreIdSelected = {
  type: 'restoreIdSelected';
  restoreId: string;
};

export const postRestoreIdSelected = async (
  restoreId: string | undefined
): Promise<void> => {
  if (!restoreId) return;

  sendObject({ type: 'restoreIdSelected', restoreId });
};
