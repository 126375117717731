const EXCLAMATION_SIZE = '20px';
const DOLLAR_SIZE = '18px';
const STAR_SIZE = '18px';
const DIAMOND_SIZE = '17px';
const CHECK_SIZE = '17px';

export type BoxIconType =
  | 'check'
  | 'diamond'
  | 'exclamation-gray'
  | 'exclamation-gold'
  | 'exclamation-black'
  | 'exclamation-white'
  | 'dollar-gray'
  | 'dollar-gold'
  | 'dollar-black'
  | 'star-gray'
  | 'star-gold';

// create an empty Record with BoxIconType keys and JSX values
export const boxIcons: Record<BoxIconType, JSX.Element> = {
  'exclamation-gray': (
    <img
      className="center"
      style={{ height: EXCLAMATION_SIZE, maxHeight: EXCLAMATION_SIZE }}
      src="GMM-icons/GMM-icon-exclamation-gray.svg"
    />
  ),
  'exclamation-gold': (
    <img
      className="center"
      style={{ height: EXCLAMATION_SIZE, maxHeight: EXCLAMATION_SIZE }}
      src="GMM-icons/GMM-icon-exclamation-gold.svg"
    />
  ),
  'exclamation-black': (
    <img
      className="center"
      style={{ height: EXCLAMATION_SIZE, maxHeight: EXCLAMATION_SIZE }}
      src="GMM-icons/GMM-icon-exclamation-black.svg"
    />
  ),
  'exclamation-white': (
    <img
      className="center"
      style={{ height: EXCLAMATION_SIZE, maxHeight: EXCLAMATION_SIZE }}
      src="GMM-icons/GMM-icon-exclamation-white.svg"
    />
  ),
  'dollar-gray': (
    <img
      className="center"
      style={{ height: DOLLAR_SIZE, maxHeight: DOLLAR_SIZE }}
      src="GMM-icons/GMM-icon-questions-dollar-gray.svg"
    />
  ),
  'dollar-gold': (
    <img
      className="center"
      style={{ height: DOLLAR_SIZE, maxHeight: DOLLAR_SIZE }}
      src="GMM-icons/GMM-icon-questions-dollar-gold.svg"
    />
  ),
  'dollar-black': (
    <img
      className="center"
      style={{ height: DOLLAR_SIZE, maxHeight: DOLLAR_SIZE }}
      src="GMM-icons/GMM-icon-questions-dollar-black.svg"
    />
  ),
  'star-gray': (
    <img
      className="center"
      style={{ height: STAR_SIZE, maxHeight: STAR_SIZE }}
      src="GMM-icons/GMM-icon-questions-star-gray.svg"
    />
  ),
  'star-gold': (
    <img
      className="center"
      style={{ height: STAR_SIZE, maxHeight: STAR_SIZE }}
      src="GMM-icons/GMM-icon-questions-star-gold.svg"
    />
  ),
  check: (
    <img
      className="center"
      style={{ height: CHECK_SIZE, maxHeight: CHECK_SIZE }}
      src="GMM-icons/GMM-icon-exam-check.svg"
    />
  ),
  diamond: (
    <img
      className="center"
      style={{ height: DIAMOND_SIZE, maxHeight: DIAMOND_SIZE }}
      src="GMM-icons/GMM-icon-exam-diamond.svg"
    />
  ),
};
